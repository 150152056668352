import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  notification,
  Upload,
  Row,
  Col,
  Select,
  DatePicker,
  Button,
} from "antd";
import { EditOutlined, DownloadOutlined } from "@ant-design/icons";
import Joi from "joi-browser";
import _ from "lodash";
import http from "../../helpers/http";
import config from "../../config/config";
import HtmlEditor from "../../components/HtmlEditor";
import helper from "../../helpers/helper";
import slugify from "react-slugify";
import moment from "moment";

function Edit({ isVisible, handleClose, dbData, moduleName, categories }) {
  const [form] = Form.useForm();
  const [loading, setloading] = useState(false);
  const [formError, setFormError] = useState({});
  const [date, setDate] = useState();

  const base_url = config.api_url + "admin/media/template-3"; //without trailing slash
  const image_url = config.api_url + "uploads/media/"; //with trailing slash
  const sizeLimit = 100;

  const { Option } = Select;

  const schema = Joi.object({
    mediaId: Joi.string().trim().required().label("media report"),

    title: Joi.string().trim().required(),
    date: Joi.required(),
  }).options({ allowUnknown: true });

  const validate = async (value) => {
    const { error } = Joi.validate(value, schema, {
      abortEarly: false,
      allowUnknown: true,
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    if (value.file) {
      if (value.file.file.size > sizeLimit * 1024 * 1024) {
        errors["file"] = `File needs to be under ${sizeLimit}MB`;
      }
      let allowedExt = [
        "image/jpeg",
        "image/png",
        "image/svg+xml",
        "image/svg",
        "application/pdf",
      ];
      if (!allowedExt.includes(value.file.file.type)) {
        errors["file"] = "File does not have a valid file extension.";
      }
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (value) => {
    let errors = await validate(value);

    setFormError(errors);

    if (Object.keys(errors).length) {
      return;
    }

    setloading(true);
    let formData = new FormData();
    for (const [key, data] of Object.entries(_.omit(value, ["file"]))) {
      formData.append(key, data || "");
    }

    if (value.file) {
      formData.append("file", value.file.file);
    }

    const { data } = await http.put(`${base_url}/${dbData._id}`, formData);

    if (data) {
      notification["success"]({
        message: `${moduleName} Updated Successfully`,
      });
      setloading(false);
      form.resetFields();
      handleClose();
    }
  };
  useEffect(() => {
    form.setFieldsValue(_.omit(dbData, ["file", "date"]));
    setDate(moment(dbData.date).format("YYYY-MM-DD"));
  }, [dbData]);

  return (
    <Modal
      title={`Edit ${moduleName}`}
      visible={isVisible}
      onCancel={handleClose}
      okText="Update"
      cancelText="Cancel"
      confirmLoading={loading}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            values.date = date;
            handleSubmit(values);
          })
          .catch((info) => {});
      }}
    >
      <Form layout="vertical" form={form}>
        <Row gutter={15}>
          <Col span={24}>
            <Form.Item
              required
              label="Title:"
              name="title"
              {...(formError.title && {
                help: formError.title,
                validateStatus: "error",
              })}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              required
              label="Date:"
              name="date"
              {...(formError.date && {
                help: formError.date,
                validateStatus: "error",
              })}
            >
              <DatePicker
                style={{
                  width: "100%",
                }}
                defaultValue={moment(dbData.date)}
                onChange={(date, dateString) => {
                  setDate(dateString);
                }}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              required
              label="Media Report:"
              name="mediaId"
              {...(formError.mediaId && {
                help: formError.mediaId,
                validateStatus: "error",
              })}
            >
              <Select
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {categories &&
                  categories.map((data) => {
                    return <Option value={data._id}>{data.name}</Option>;
                  })}
              </Select>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              required
              label="File:"
              name="file"
              {...(formError.file && {
                help: formError.file,
                validateStatus: "error",
              })}
            >
              <Upload
                beforeUpload={(file) => {
                  return false;
                }}
                maxCount={1}
                listType="text"
                showUploadList={{
                  showPreviewIcon: false,
                  showRemoveIcon: false,
                  showDownloadIcon: true,
                  downloadIcon: <DownloadOutlined />,
                }}
                defaultFileList={[
                  {
                    status: "done",
                    url: image_url + dbData.file,
                    name: dbData.file,
                  },
                ]}
              >
                <Button icon={<EditOutlined />}>Select File</Button>
              </Upload>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default Edit;
