import React, { useState, useEffect } from "react";
import {
  List,
  Modal,
  notification,
  Select,
  Row,
  Col,
  Form,
  Tag,
  Avatar,
} from "antd";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// import datas from "./data";

// import VirtualList from "rc-virtual-list";
import http from "../../helpers/http";
import config from "../../config/config";
import { DragHandle } from "../../components/DragHandle";
import _ from "lodash";
const Ordering = ({ isOpen, onClose, datas, categories }) => {
  const [datalist, setdatalist] = useState([]);
  const [loading, setloading] = useState(false);
  const [subCategories, setSubCategories] = useState();
  const [policies, setpolicies] = useState();

  const base_url = config.api_url + "admin/investors/template-3"; //without trailing slash

  const moduleName = "Report";
  const { Option } = Select;

  useEffect(() => {
    // setdatalist(datas);
  }, [datas]);

  const handleOrder = async (updatedData) => {
    setloading(true);
    const { data } = await http.put(`${base_url}/sort`, updatedData);
    console.log(data);
    if (data) {
      notification["success"]({
        message: `${moduleName} Sorting Updated Successfully`,
      });
      onClose();
    }
    setloading(false);
  };

  const filterData = async (policy) => {
    const filtered = _.filter(datas, {
      investorId: { _id: policy },
    });

    setdatalist(filtered);
  };

  const fetchSubCategory = async (category) => {
    const data = await http.get(
      config.api_url + `admin/investors/subcategories?category=${category}`
    );

    if (data) {
      setSubCategories(data.data);
    }
  };
  const fetchPolicies = async (category) => {
    const data = await http.get(
      config.api_url +
        `admin/investors?subcategory=${category}&status=1&template=temp_3`
    );

    if (data) {
      setpolicies(data.data);
    }
  };

  return (
    <>
      <Modal
        title="Ordering"
        visible={isOpen}
        onOk={() => {
          handleOrder(datalist);
        }}
        okText="Update"
        confirmLoading={loading}
        onCancel={onClose}
      >
        <Row gutter={[15, 15]}>
          <Col span={24}>
            <Form layout="vertical">
              <Form.Item label="Category:" name="mainCategory">
                <Select
                  placeholder="Select Category"
                  onChange={(value) => {
                    fetchSubCategory(value);
                  }}
                >
                  {categories &&
                    categories.map((data) => {
                      return <Option value={data._id}>{`${data.name}`}</Option>;
                    })}
                </Select>
              </Form.Item>
              <Form.Item
                label="Sub Category:"
                name="investorCategory"
                // {...(formError.investorCategory && {
                //   help: formError.investorCategory,
                //   validateStatus: "error",
                // })}
              >
                <Select
                  placeholder="Select Sub Category"
                  onChange={(value) => {
                    filterData(value);
                    fetchPolicies(value);
                  }}
                  //   filterOption={(input, option) =>
                  //     option.children.toLowerCase().includes(input.toLowerCase())
                  //   }
                  // showSearch
                >
                  {subCategories &&
                    subCategories.map((data) => {
                      return (
                        <Option value={data._id}>
                          {`${data.title} `}{" "}
                          {!data.showInMenu ? (
                            <Tag color="red">Not Listed</Tag>
                          ) : (
                            ``
                          )}{" "}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <Form.Item
                label="Policy:"
                // name="investorCategory"
                // {...(formError.investorCategory && {
                //   help: formError.investorCategory,
                //   validateStatus: "error",
                // })}
              >
                <Select
                  placeholder="Select Policy"
                  onChange={(value) => {
                    filterData(value);
                  }}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  showSearch
                >
                  {policies &&
                    policies.map((data) => {
                      return <Option value={data._id}>{`${data.name}`}</Option>;
                    })}
                </Select>
              </Form.Item>
            </Form>
          </Col>
          <Col span={24}>
            <DragDropContext
              onDragEnd={(param) => {
                const id = param.draggableId;
                const srcI = param.source.index;
                const sort_order = param.destination?.index;

                if (sort_order) {
                  // handleOrder(id,sort_order);
                  let temparray = [...datalist];
                  temparray.splice(sort_order, 0, temparray.splice(srcI, 1)[0]);

                  setdatalist(temparray);
                  //   datas.saveList(datalist);
                }
              }}
            >
              <div>
                <Droppable droppableId="droppable-1">
                  {(provided, _) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      style={{ maxHeight: 400, overflow: "auto" }}
                    >
                      <List
                        // footer={<div>Footer</div>}
                        bordered
                        dataSource={datalist}
                        renderItem={(item, i) => (
                          <>
                            <Draggable
                              key={item._id}
                              draggableId={item._id}
                              index={i}
                            >
                              {(provided, snapshot) => (
                                <div ref={provided.innerRef}>
                                  <List.Item
                                    {...provided.draggableProps}
                                    style={{
                                      ...provided.draggableProps.style,
                                      boxShadow: snapshot.isDragging
                                        ? "0 0 .4rem #666"
                                        : "none",
                                    }}
                                  >
                                    <List.Item.Meta
                                      title={item.title}
                                      description={`${
                                        item.year >0
                                          ? `Year: ${
                                              item.year
                                            } - ${item.year+1}`
                                          : ``
                                      }   ${
                                        item.quarter
                                          ? `| Quarter: ${item.quarter}`
                                          : ``
                                      }   `}
                                    />
                                    {/* <span>{item.title}</span> */}
                                    <DragHandle {...provided.dragHandleProps} />
                                  </List.Item>
                                </div>
                              )}
                            </Draggable>
                          </>
                        )}
                      />

                      {/* {datalist.map((item, i) => (
                  <Draggable
                    key={item.id}
                    draggableId={"draggable-" + item.id}
                    index={i}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={{
                          ...provided.draggableProps.style,
                          boxShadow: snapshot.isDragging
                            ? "0 0 .4rem #666"
                            : "none",
                        }}
                      >
                        <DragHandle {...provided.dragHandleProps} />
                        <span>{item.title}</span>
                      </div>
                    )}
                  </Draggable>
                ))} */}

                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            </DragDropContext>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default Ordering;
