import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  notification,
  Row,
  Col,
  Select,
  Upload,
  Breadcrumb,
  Card,
  Button,
  Space,
  Divider 
} from "antd";

import Joi from "joi-browser";

import http from "../../helpers/http";

import config from "../../config/config";

import _ from "lodash";

import HtmlEditor from "../../components/HtmlEditor";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";

const Create = (props) => {
  const [form] = Form.useForm();
  const [loading, setloading] = useState(false);
  const [formError, setFormError] = useState({});
  const [content, setContent] = useState();
  const [categories, setCategories] = useState();
  const [columns, setcolumns] = useState([{ label: "Name", type: "single" }]);

  const base_url = config.api_url + "admin/investors/template-4"; //without trailing slash
  const image_url = config.api_url + "uploads/investor/"; //with trailing slash
  const sizeLimit = config.image_size_limit;

  const { Option } = Select;
  const { TextArea } = Input;
  //validation schema
  const moduleNamePural = "Tables";

  const moduleName = "Table";

  const validate = async (data) => {
    let schemaObj = {
      investorId: Joi.string().trim().required().label("investor policy"),
      // content: Joi.string().trim().required(),
    };

    const schema = Joi.object(schemaObj).options({ allowUnknown: true });

    const { error } = Joi.validate(data, schema, {
      abortEarly: false,
      allowUnknown: true,
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (value) => {
    let errors = await validate(value);

    setFormError(errors);

    if (Object.keys(errors).length) {
      return;
    }
    console.log({ ...value, columns });
    // return;

    const finalData = { ...value, columns };
    console.log(finalData);
    // return;
    setloading(true);
    // let formData = new FormData();
    // for (const [key, data] of Object.entries(_.omit(value, ["image"]))) {
    //   formData.append(key, data || "");
    // }
    // if (value.image) {
    //   formData.append("image", value.image.file);
    // }

    const { data } = await http.post(base_url, finalData);
    // const { data } = await http.post(base_url, value);

    if (data) {
      notification["success"]({
        message: `${moduleName} Added Successfully`,
      });
      setloading(false);
      props.history.push("/investors/template-4");
    }
  };

  const onColumnFinish = (values) => {
    setcolumns(values.columns);
    console.log("Received values of columns:", values);
  };

  const fetchCategory = async () => {
    const data = await http.get(config.api_url + "admin/investors");

    if (data) {
      setCategories(data.data);
    }
  };
  useEffect(() => {
    fetchCategory();
  }, []);

  return (
    // <Modal
    //   title={`Add ${moduleName}`}
    //   visible={isVisible}
    //   onCancel={handleClose}
    //   okText="Create"
    //   cancelText="Cancel"
    //   confirmLoading={loading}
    //   onOk={() => {
    //     form
    //       .validateFields()
    //       .then((values) => {
    //         values.content = content;

    //         handleSubmit(values);
    //       })
    //       .catch((info) => {});
    //   }}
    //   width={"90%"}
    //   style={{
    //     top: 20,
    //   }}
    // >

    // </Modal>

    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
        <Breadcrumb.Item>Investor</Breadcrumb.Item>
        <Breadcrumb.Item>{moduleNamePural}</Breadcrumb.Item>

      </Breadcrumb>

      {/* <Row>
  <Col span={24}> */}
      <div className="site-card-border-less-wrapper">
        <Card  bordered={false} style={{ overflow: "scroll" }}>
          <Form
            // layout="vertical"
            name="dynamic_form_nest_item"
            onFinish={onColumnFinish}
            style={{
              maxWidth: 600,
            }}
            autoComplete="off"
          >
            <Form.List name="columns" initialValue={columns}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space
                      key={key}
                      style={{
                        display: "flex",
                        marginBottom: 8,
                      }}
                      align="baseline"
                    >
                      {/* <Form.Item
                        {...restField}
                        name={[name, "name"]}
                        rules={[
                          {
                            required: true,
                            message: "Missing Column name",
                          },
                        ]}
                      >
                        <Input placeholder="Column Name" />
                      </Form.Item> */}
                      <Form.Item
                        {...restField}
                        name={[name, "label"]}
                        label="Column Label:"
                        rules={[
                          {
                            required: true,
                            message: "Missing Column Label",
                          },
                        ]}
                      >
                        <Input placeholder="Column Label" />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "type"]}
                        label="Input Type:"
                        rules={[
                          {
                            required: true,
                            message: "Missing Input Type",
                          },
                        ]}
                      >
                        <Select placeholder="Select input type" >
                          {["single", "multiple"].map((data) => {
                            return <Option value={data}>{data}</Option>;
                          })}
                        </Select>
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Space>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add Column
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Save Columns
              </Button>
            </Form.Item>
          </Form>
          <Divider />
          <Form layout="vertical" form={form} onFinish={handleSubmit}>
            <Row gutter={15}>
              <Col span={12}>
                <Form.Item
                  required
                  label="Investor Policy:"
                  name="investorId"
                  {...(formError.investorId && {
                    help: formError.investorId,
                    validateStatus: "error",
                  })}
                >
                  <Select
                    placeholder="Select Policy"
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {categories &&
                      categories.map((data) => {
                        return (
                          <Option
                            value={data._id}
                          >{`${data.investorCategory?.title} > ${data.name}`}</Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Form.List name="rows" initialValue={[{ field0: "" }]}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }, rowId) => (
                    <Space
                      key={key}
                      style={{
                        display: "flex",
                        marginBottom: 8,
                      }}
                      align="center"
                    >
                      {columns?.map((column, index) => {
                        return (
                          <>
                            <Form.Item
                              {...restField}
                              name={[name, `field${index}`]}
                              style={{ width: 250 }}
                              label={rowId == 0 ? `${column.label || ""}:` : ``}
                              rules={
                                index == 0
                                  ? [
                                      {
                                        required: true,
                                        message: `${column.label} is required`,
                                      },
                                    ]
                                  : []
                              }
                            >
                              {column.type == "single" ? (
                                <Input placeholder={column.label} />
                              ) : (
                                <TextArea rows={3} placeholder={column.label} />
                              )}
                            </Form.Item>
                          </>
                        );
                      })}

                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Space>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => {
                        if (columns.length) {
                          add();
                        }
                      }}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add Row
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
      {/* </Col>
</Row> */}
    </div>
  );
};

export default Create;
