import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  notification,
  Upload,
  Row,
  Col,
  Radio,
  InputNumber,
} from "antd";
import { EditOutlined } from "@ant-design/icons";
import Joi from "joi-browser";
import _ from "lodash";
import http from "../../helpers/http";
import config from "../../config/config";

function Edit({ isVisible, handleClose, dbData, moduleName }) {
  const [form] = Form.useForm();
  const [loading, setloading] = useState(false);
  const [formError, setFormError] = useState({});
  const [isVideoVisible, setisVideoVisible] = useState(false);

  const base_url = config.api_url + "admin/sliders";
  const image_url = config.api_url + "uploads/slider/";
  const sizeLimit = 1;

  // form.setFieldsValue(_.omit(dbData, ["image", "thumbnail"]));

  const validate = (value) => {
    let schemaObj = {
      type: Joi.required(),
    };
    console.log(value);
    if (value.type === "image" && dbData.type != "image" && !dbData.image) {
      schemaObj.image = Joi.required();
    }
    if (value.type === "video" && dbData.type != "video" && !dbData.thumbnail) {
      schemaObj.thumbnail = Joi.required();
    }
    if (value.type === "video") {
      schemaObj.url = Joi.string().uri().required().label("youtube embed url");
    }

    const schema = Joi.object(schemaObj).options({ allowUnknown: true });

    const { error } = Joi.validate(value, schema, {
      abortEarly: false,
      allowUnknown: true,
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    if (value.image) {
      if (value.image.file.size > sizeLimit * 1024 * 1024) {
        errors["image"] = `File needs to be under ${sizeLimit}MB`;
      }
      let allowedExt = [
        "image/jpeg",
        "image/png",
        "image/svg+xml",
        "image/svg",
      ];
      if (!allowedExt.includes(value.image.file.type)) {
        errors["image"] = "File does not have a valid file extension.";
      }
    }
    if (value.thumbnail) {
      if (value.thumbnail.file.size > sizeLimit * 1024 * 1024) {
        errors["thumbnail"] = `File needs to be under ${sizeLimit}MB`;
      }
      let allowedExt = [
        "image/jpeg",
        "image/png",
        "image/svg+xml",
        "image/svg",
      ];
      if (!allowedExt.includes(value.thumbnail.file.type)) {
        errors["thumbnail"] = "File does not have a valid file extension.";
      }
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (value) => {
    let errors = validate(value);
    setFormError(errors);

    if (Object.keys(errors).length) {
      return;
    }

    setloading(true);
    let formData = new FormData();

    for (const [key, data] of Object.entries(
      _.omit(value, ["image", "thumbnail", "sort_order"])
    )) {
      formData.append(key, data || "");
    }
    if (value.image) {
      formData.append("image", value.image.file);
    }
    if (value.thumbnail) {
      formData.append("thumbnail", value.thumbnail.file);
    }

    formData.append("sort_order", value.sort_order);

    const { data } = await http.put(`${base_url}/${dbData._id}`, formData);

    if (data) {
      notification["success"]({
        message: `${moduleName} Updated Successfully`,
      });
      setloading(false);
      form.resetFields();
      handleClose();
    }
  };

  const handleTypeChange = (type) => {
    if (type.target.value === "video") {
      setisVideoVisible(true);
    } else {
      setisVideoVisible(false);
    }
  };

  useEffect(() => {
    form.setFieldsValue(_.omit(dbData, ["image", "thumbnail"]));

    if (dbData.type === "video") {
      setisVideoVisible(true);
    } else {
      setisVideoVisible(false);
    }
  }, [dbData]);

  return (
    <Modal
      title={`Edit ${moduleName}`}
      visible={isVisible}
      onCancel={handleClose}
      okText="Update"
      cancelText="Cancel"
      confirmLoading={loading}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            handleSubmit(values);
          })
          .catch((info) => {});
      }}
      // width={1000}
    >
      <Form layout="vertical" form={form}>
        <Row>
          <Col span={12}>
            <Form.Item
              label="Banner Type"
              required
              name="type"
              {...(formError.type && {
                help: formError.type,
                validateStatus: "error",
              })}
            >
              <Radio.Group onChange={handleTypeChange}>
                <Radio value="image" checked>
                  Image
                </Radio>
                <Radio value="video"> Video </Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Sort Order"
              required
              name="sort_order"
              {...(formError.sort_order && {
                help: formError.sort_order,
                validateStatus: "error",
              })}
            >
              <InputNumber min={0} defaultValue={0} />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ display: !isVideoVisible ? "block" : "none" }}>
          <Col span={24}>
            <Form.Item
              required
              label="Image: (1630 X 670)"
              name="image"
              {...(formError.image && {
                help: formError.image,
                validateStatus: "error",
              })}
            >
              <Upload
                beforeUpload={(file) => {
                  return false;
                }}
                maxCount={1}
                listType="picture-card"
                showUploadList={{
                  showPreviewIcon: false,
                  showRemoveIcon: false,
                }}
                defaultFileList={[
                  {
                    thumbUrl: dbData.image
                      ? image_url + dbData.image
                      : "https://via.placeholder.com/100",
                  },
                ]}
              >
                <EditOutlined />
              </Upload>
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ display: isVideoVisible ? "block" : "none" }}>
          <Col span={24}>
            <Form.Item
              required
              label="Thumbnail: "
              name="thumbnail"
              {...(formError.thumbnail && {
                help: formError.thumbnail,
                validateStatus: "error",
              })}
            >
              <Upload
                beforeUpload={(file) => {
                  return false;
                }}
                maxCount={1}
                listType="picture-card"
                showUploadList={{
                  showPreviewIcon: false,
                  showRemoveIcon: false,
                }}
                defaultFileList={[
                  {
                    thumbUrl: dbData.thumbnail
                      ? image_url + dbData.thumbnail
                      : "https://via.placeholder.com/100",
                  },
                ]}
              >
                <EditOutlined />
              </Upload>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              required
              label="URL:"
              name="url"
              {...(formError.url && {
                help: formError.url,
                validateStatus: "error",
              })}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default Edit;
