import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  Row,
  Col,
  Card,
  Space,
  Button,
  Modal,
  notification,
  Switch,
  Image,
} from "antd";
import { Table } from "ant-table-extensions";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Edit from "./Edit";
import Create from "./Create";
import http from "../../helpers/http";
import config from "../../config/config";

function Index(props) {
  const { confirm } = Modal;
  const [editOpen, setEditOpen] = useState(false);
  const [createOpen, setCreateOpen] = useState(false);
  const [data, setData] = useState({});
  //createKey and editKey is used to reset modal data on close
  const [createKey, setCreateKey] = useState(1);
  const [editKey, seteditKey] = useState(1);
  const [datas, setDatas] = useState([]);

  const moduleName = "Slider";
  const moduleNamePural = "Sliders";

  const base_url = config.api_url + "admin/sliders";
  const image_url = config.api_url + "uploads/slider/";

  const handleEdit = async (id) => {
    const { data } = await http.get(`${base_url}/${id}`);

    if (data) {
      seteditKey((state) => {
        return state + 1;
      });
      setData(data);
      setEditOpen(true);
    }
  };
  const handleCreate = () => {
    setCreateOpen(true);
  };

  const handleEditClose = () => {
    seteditKey((state) => {
      return state + 1;
    });
    setData({});
    setEditOpen(false);
    fetchDatas();
  };

  const handleCreateClose = () => {
    setCreateKey(createKey + 1);
    setCreateOpen(false);
    fetchDatas();
  };

  const handleStatus = async (value, id) => {
    const status = value ? 1 : 0;

    const result = await http.put(`${base_url}/status/${id}`, {
      status: status,
    });

    if (result) {
      notification["success"]({
        message: "Status Changed Successfully",
      });
    }
  };

  const handleDelete = async (id) => {
    const result = await http.delete(`${base_url}/${id}`);
    if (result) {
      notification["success"]({
        message: `${moduleName} Deleted Successfully`,
      });
    }

    fetchDatas();
  };

  const showDeleteConfirm = (id) => {
    confirm({
      title: `Are you sure delete this ${moduleName}?`,
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",

      onOk() {
        return handleDelete(id);
      },
      onCancel() {},
    });
  };

  const fetchDatas = async () => {
    const data = await http.get(base_url);

    if (data) {
      setDatas(data.data);
    }
  };

  useEffect(() => {
    const query = new URLSearchParams(props.location.search);
    const token = query.get("demo");
    console.log(token); //123
    fetchDatas();
  }, []);

  const columns = [
    {
      title: "Image",

      key: "image",
      render: (text, record) => (
        <Image
          width={100}
          src={
            record.type == "image"
              ? image_url + record.image
              : image_url + record.thumbnail
          }
          fallback="https://via.placeholder.com/100"
        />
      ),
    },
    {
      title: "type",
      dataIndex: "type",
      key: "type",
    },

    {
      title: "Sort Order",
      dataIndex: "sort_order",
      key: "sort_order",
    },
    {
      title: "Status",
      key: "status",
      render: (text, record) => (
        <Space size="middle">
          <Switch
            checkedChildren="On"
            unCheckedChildren="Off"
            onChange={(value) => {
              handleStatus(value, record._id);
            }}
            defaultChecked={record.status}
          />
        </Space>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Button
            type="primary"
            onClick={() => {
              handleEdit(record._id);
            }}
          >
            Edit
          </Button>
          <Button
            type="danger"
            onClick={() => {
              showDeleteConfirm(record._id);
            }}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
        <Breadcrumb.Item>{moduleNamePural}</Breadcrumb.Item>
      </Breadcrumb>

      <Row>
        <Col span={24}>
          <div className="site-card-border-less-wrapper">
            <Card title={moduleNamePural} bordered={false}>
              <Row style={{ marginBottom: "20px" }}>
                <Col span={24}>
                  <Button type="primary" onClick={handleCreate}>
                    Add {moduleName}
                  </Button>
                </Col>
              </Row>

              <Table
                rowKey={(record) => record._id}
                columns={columns}
                dataSource={datas}
              />

              <Edit
                key={"edit" + editKey}
                isVisible={editOpen}
                handleClose={handleEditClose}
                dbData={data}
                moduleName={moduleName}
              />
              <Create
                key={"create" + createKey}
                isVisible={createOpen}
                handleClose={handleCreateClose}
                moduleName={moduleName}
              />
            </Card>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Index;
