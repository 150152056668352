import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  notification,
  Upload,
  Row,
  Col,
  Select,
  DatePicker,
  Button,
} from "antd";
import Joi from "joi-browser";
// import Joi from "joi";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import slugify from "react-slugify";
import http from "../../helpers/http";
import helper from "../../helpers/helper";
import config from "../../config/config";
import HtmlEditor from "../../components/HtmlEditor";
import axios from "axios";
import _ from "lodash";

const Create = ({ isVisible, handleClose, moduleName, categories }) => {
  const [form] = Form.useForm();
  const [loading, setloading] = useState(false);
  const [formError, setFormError] = useState({});
  const [date, setDate] = useState();
  const [year, setYear] = useState(0);

  const base_url = config.api_url + "admin/investors/template-3"; //without trailing slash
  const image_url = config.api_url + "uploads/investor/"; //with trailing slash
  const sizeLimit = config.file_size_limit;

  const { Option } = Select;
  //validation schema

  const schema = Joi.object({
    investorId: Joi.string().trim().required().label("investor policy"),

    title: Joi.string().trim().required(),
    date: Joi.required(),
    file: Joi.required(),
  }).options({ allowUnknown: true });

  const validate = async (data) => {
    const { error } = Joi.validate(data, schema, {
      abortEarly: false,
      allowUnknown: true,
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    if (data.file) {
      if (data.file.file.size > sizeLimit * 1024 * 1024) {
        errors["file"] = `File needs to be under ${sizeLimit}MB`;
      }
      let allowedExt = ["application/pdf"];
      // if (!allowedExt.includes(data.file.file.type)) {
      //   errors["file"] = "File does not have a valid file extension.";
      // }
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (value) => {
    let errors = await validate(value);
    setFormError(errors);

    if (Object.keys(errors).length) {
      return;
    }

    setloading(true);
    let formData = new FormData();
    for (const [key, data] of Object.entries(_.omit(value, ["file"]))) {
      formData.append(key, data || "");
    }

    formData.append("file", value.file.file);

    const { data } = await http.post(base_url, formData);

    if (data) {
      notification["success"]({
        message: `${moduleName} Added Successfully`,
      });
      setloading(false);

      // form.resetFields();

      handleClose();
    }
  };

  return (
    <Modal
      title={`Add ${moduleName}`}
      visible={isVisible}
      onCancel={handleClose}
      okText="Create"
      cancelText="Cancel"
      confirmLoading={loading}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            values.date = date;
            values.year = year;

            handleSubmit(values);
          })
          .catch((info) => {});
      }}
    >
      <Form layout="vertical" form={form}>
        <Row gutter={15}>
          <Col span={24}>
            <Form.Item
              required
              label="Title:"
              name="title"
              {...(formError.title && {
                help: formError.title,
                validateStatus: "error",
              })}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              required
              label="Date:"
              name="date"
              {...(formError.date && {
                help: formError.date,
                validateStatus: "error",
              })}
            >
              <DatePicker
                style={{
                  width: "100%",
                }}
                onChange={(date, dateString) => {
                  setDate(dateString);
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              required
              label="Investor Policy:"
              name="investorId"
              {...(formError.investorId && {
                help: formError.investorId,
                validateStatus: "error",
              })}
            >
              <Select
                placeholder="Select Policy"
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {categories &&
                  categories.map((data) => {
                    return (
                      <Option
                        value={data._id}
                      >{`${data.investorCategory?.title} > ${data.name}`}</Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              
              label="Year:"
              name="year"
              {...(formError.year && {
                help: formError.year,
                validateStatus: "error",
              })}
            >
              <DatePicker
               picker="year"
                style={{
                  width: "100%",
                }}
                onChange={(date, dateString) => {
                  console.log(dateString);
                  setYear(dateString);
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              
              label="Quarter:"
              name="quarter"
              {...(formError.quarter && {
                help: formError.quarter,
                validateStatus: "error",
              })}
            >
               <Select
                placeholder="Select Quarter"
                
              >
               <Option value="Q1">Q1</Option>
               <Option value="Q2">Q2</Option>
               <Option value="Q3">Q3</Option>
               <Option value="Q4">Q4</Option>
              </Select>
            </Form.Item>
          </Col>

         

          <Col span={24}>
            <Form.Item
              required
              label="File:"
              name="file"
              {...(formError.file && {
                help: formError.file,
                validateStatus: "error",
              })}
            >
              <Upload
                beforeUpload={(file) => {
                  return false;
                }}
                maxCount={1}
                listType="text"
                showUploadList={{
                  showPreviewIcon: true,
                  showRemoveIcon: false,
                }}
              >
                <Button icon={<UploadOutlined />}>Select File</Button>
                {/* <PlusOutlined /> */}
              </Upload>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default Create;
