import React, { useEffect, useState } from "react";
import { Modal, Form, Input, notification, Upload, Row, Col } from "antd";
import { EditOutlined } from "@ant-design/icons";
import Joi from "joi-browser";
import _ from "lodash";
import http from "../../helpers/http";
import config from "../../config/config";
import HtmlEditor from "../../components/HtmlEditor";
import helper from "../../helpers/helper";
import slugify from "react-slugify";

function Edit({ isVisible, handleClose, dbData, moduleName }) {
  const [form] = Form.useForm();
  const [loading, setloading] = useState(false);
  const [formError, setFormError] = useState({});
  const [description, setDescription] = useState();

  const base_url = config.api_url + "admin/locations/categories"; //without trailing slash
  const image_url = config.api_url + "uploads/location/"; //with trailing slash
  const sizeLimit = 1;

  const schema = Joi.object({
    name: Joi.string().trim().required(),
  }).options({ allowUnknown: true });

  const validate = async (value) => {
    const { error } = Joi.validate(value, schema, {
      abortEarly: false,
      allowUnknown: true,
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    // if (value.slug && dbData.slug != value.slug) {
    //   const result = await helper.validateSlug(value.slug, "BrandCategory");

    //   if (result == false) {
    //     errors["slug"] = `This slug is already used.`;
    //   }
    // }

    // if (value.image) {
    //   if (value.image.file.size > sizeLimit * 1024 * 1024) {
    //     errors["image"] = `File needs to be under ${sizeLimit}MB`;
    //   }
    //   let allowedExt = [
    //     "image/jpeg",
    //     "image/png",
    //     "image/svg+xml",
    //     "image/svg",
    //   ];
    //   if (!allowedExt.includes(value.image.file.type)) {
    //     errors["image"] = "File does not have a valid file extension.";
    //   }
    // }

    return errors ? errors : null;
  };

  const handleSubmit = async (value) => {
    let errors = await validate(value);

    setFormError(errors);

    if (Object.keys(errors).length) {
      return;
    }

    setloading(true);
    // let formData = new FormData();
    // for (const [key, data] of Object.entries(_.omit(value, ["image"]))) {
    //   formData.append(key, data || "");
    // }

    // if (value.image) {
    //   formData.append("image", value.image.file);
    // }
    //
    const { data } = await http.put(`${base_url}/${dbData._id}`, value);

    if (data) {
      notification["success"]({
        message: `${moduleName} Updated Successfully`,
      });
      setloading(false);
      form.resetFields();
      handleClose();
    }
  };
  useEffect(() => {
    form.setFieldsValue(_.omit(dbData, ["image", "description"]));

    setDescription(() => {
      return dbData.description;
    });
  }, [dbData]);

  return (
    <Modal
      title={`Edit ${moduleName}`}
      visible={isVisible}
      onCancel={handleClose}
      okText="Update"
      cancelText="Cancel"
      confirmLoading={loading}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            values.description = description;
            handleSubmit(values);
          })
          .catch((info) => {});
      }}
    >
      <Form layout="vertical" form={form}>
        <Row gutter={15}>
          <Col span={24}>
            <Form.Item
              required
              label="Name:"
              name="name"
              {...(formError.name && {
                help: formError.name,
                validateStatus: "error",
              })}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default Edit;
