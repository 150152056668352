import React, { useEffect, useState } from "react";
// import tinymce from "tinymce/tinymce";
import {
  Breadcrumb,
  Row,
  Col,
  Card,
  Space,
  Button,
  Modal,
  notification,
  Switch,
  Image,
} from "antd";
import { Table } from "ant-table-extensions";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Edit from "./Edit";
import Create from "./Create";
import Ordering from "./Ordering";

import http from "../../helpers/http";
import config from "../../config/config";

function Index() {
  const { confirm } = Modal;
  const [editOpen, setEditOpen] = useState(false);
  const [createOpen, setCreateOpen] = useState(false);
  const [isOrderOpen, setisOrderOpen] = useState(false);

  const [data, setData] = useState({});
  const [loading, setloading] = useState(false);
  // const [templates, setTemplate] = useState([
  //   { title: "temp_1", label: "Template 1 (Overview)" },
  //   { title: "temp_2", label: "Template 2 (Board Of Director)" },
  //   { title: "temp_3", label: "Template 3 (PDF Reports)" },
  //   { title: "external", label: "External Link" },
  // ]);

  //createKey and editKey is used to reset modal data on close
  const [createKey, setCreateKey] = useState(Math.random() * 10);
  const [editKey, seteditKey] = useState(Math.random() * 10);
  const [orderKey, setorderKey] = useState(Math.random() * 10);
  const [datas, setDatas] = useState([]);

  const moduleName = "Category";
  const moduleNamePural = "Investor Categories";
  const base_url = config.api_url + "admin/investors/categories"; //without trailing slash
  const image_url = config.api_url + "uploads/investor/"; //with trailing slash

  const handleEdit = async (id) => {
    const { data } = await http.get(`${base_url}/${id}`);

    if (data) {
      seteditKey((state) => {
        return state + Math.random() * 2;
      });
      setData(data);
      setEditOpen(true);
    }
  };
  const handleCreate = () => {
    setCreateKey((state) => {
      return state + Math.random() * 2;
    });
    setCreateOpen(true);
  };

  const handleEditClose = () => {
    seteditKey((state) => {
      return state + Math.random() * 2;
    });
    setData({});
    setEditOpen(false);
    fetchDatas();
  };

  const handleCreateClose = () => {
    setCreateKey((state) => {
      return state + Math.random() * 2;
    });

    setCreateOpen(false);
    fetchDatas();
  };

  const handleOrderClose = () => {
    setisOrderOpen(!isOrderOpen);
    fetchDatas();
  };

  const handleStatus = async (value, id) => {
    const status = value ? 1 : 0;

    const result = await http.put(`${base_url}/status/${id}`, {
      status: status,
    });

    if (result) {
      notification["success"]({
        message: "Status Changed Successfully",
      });
    }
  };

  const handleDelete = async (id) => {
    const result = await http.delete(`${base_url}/${id}`);
    if (result) {
      notification["success"]({
        message: `${moduleName} Deleted Successfully`,
      });
    }

    fetchDatas();
  };

  const showDeleteConfirm = (id) => {
    confirm({
      title: `Are you sure delete this ${moduleName}?`,
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",

      onOk() {
        return handleDelete(id);
      },
      onCancel() {},
    });
  };

  const fetchDatas = async () => {
    setloading(true);
    const data = await http.get(base_url);

    if (data) {
      setDatas(data.data);
    }
    setloading(false);
  };

  // const fetchCategory = async () => {
  //   const data = await http.get(config.api_url + "admin/locations/categories");
  //
  //   if (data) {
  //     setCategories(data.data);
  //   }
  // };

  useEffect(() => {
    fetchDatas();
    // fetchCategory();
  }, []);

  const columns = [
    {
      title: "Image",

      key: "image",
      render: (text, record) => (
        <Image
          width={100}
          src={image_url + record.image}
          fallback="https://via.placeholder.com/100"
        />
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },

    {
      title: "Slug",
      dataIndex: "slug",
      key: "slug",
    },

    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Button
            type="primary"
            onClick={() => {
              handleEdit(record._id);
            }}
          >
            Edit
          </Button>
          <Button
            type="danger"
            onClick={() => {
              showDeleteConfirm(record._id);
            }}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      <div>
        <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
          <Breadcrumb.Item>{moduleNamePural}</Breadcrumb.Item>
        </Breadcrumb>

        <Row>
          <Col span={24}>
            <div className="site-card-border-less-wrapper">
              <Card title={moduleNamePural} bordered={false}>
                <Row style={{ marginBottom: "20px" }}>
                  <Col span={24}>
                    <Space>
                      <Button type="primary" onClick={handleCreate}>
                        Add {moduleName}
                      </Button>
                      <Button
                        type="primary"
                        onClick={() => {
                          console.log("order clicked");
                          setisOrderOpen(true);
                        }}
                      >
                        View Ordering
                      </Button>
                    </Space>
                  </Col>
                </Row>

                <Table
                  rowKey={(record) => record._id}
                  columns={columns}
                  dataSource={datas}
                  loading={loading}
                  // searchable
                  searchableProps={{
                    fuzzySearch: false,
                  }}
                />

                <Edit
                  key={"edit" + editKey}
                  isVisible={editOpen}
                  handleClose={handleEditClose}
                  dbData={data}
                  moduleName={moduleName}
                />
                <Create
                  key={"create" + createKey}
                  isVisible={createOpen}
                  handleClose={handleCreateClose}
                  moduleName={moduleName}
                />
                <Ordering
                  // key={"order" + orderKey}

                  isOpen={isOrderOpen}
                  onClose={handleOrderClose}
                  datas={datas}
                />
              </Card>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Index;
