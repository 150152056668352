import React, { useState } from "react";
import "./App.less";
import { Layout, Menu, Modal } from "antd";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
import {
  PieChartOutlined,
  UserOutlined,
  LogoutOutlined,
  CrownOutlined,
  LineChartOutlined,
  StarOutlined,
  ShopOutlined,
  GlobalOutlined,
  TrophyOutlined,
  PlayCircleOutlined,
  AimOutlined,
  AudioOutlined,
  PictureOutlined,
  FileTextOutlined,
  ExclamationCircleOutlined,
  FolderOpenOutlined
} from "@ant-design/icons";

import Sliders from "./pages/sliders/Index";
import BrandCategories from "./pages/brandCategories/Index";
import Brands from "./pages/brands/Index";
import Business from "./pages/businesses/Index";
import Contact from "./pages/leads/Contact";
import Partner from "./pages/leads/Partner";
import Subscribe from "./pages/leads/Subscribe";
import Sustainability from "./pages/Sustainability/Index";
import SustainabilityBlock from "./pages/SustainabilityBlock/Index";
import Award from "./pages/Award/Index";
import LocationCategory from "./pages/LocationCategory/Index";
import Location from "./pages/Location/Index";
import Interview from "./pages/Interview/Index";
import Media from "./pages/Media/Index";
import MediaTempOne from "./pages/Media-template-1/Index";
import MediaTempTwo from "./pages/Media-template-2/Index";
import MediaTempThree from "./pages/Media-template-3/Index";
import Investor from "./pages/Investor/Index";
import InvestorTempOne from "./pages/Investor-template-1/Index";
import InvestorTempTwo from "./pages/Investor-template-2/Index";
import InvestorTempThree from "./pages/Investor-template-3/Index";
import InvestorTempFour from "./pages/Investor-template-4/Index";
import InvestorTempFourCreate from "./pages/Investor-template-4/Create";
import InvestorTempFourEdit from "./pages/Investor-template-4/Edit";
import InvestorCategories from "./pages/InvestorCategories/Index";
import InvestorSubCategories from "./pages/InvestorSubCategories/Index";
import HomepageContent from "./pages/Content/Homepage";
import MediaManager from "./pages/MediaManager/Index";
import Logout from "./components/Logout";
import Logo from "./logo-white.svg";

function MainLayout(props) {
  const { Header, Content, Footer, Sider } = Layout;
  const { SubMenu } = Menu;
  const { confirm } = Modal;
  const [collapsed, setCollapsed] = useState(false);

  const onCollapse = (collapsed) => {
    setCollapsed(!collapsed);
  };

  const showLogoutConfirm = (id) => {
    confirm({
      title: `Are you sure you want to logout?`,
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",

      onOk() {
        // return handleDelete(id);
        props.history.push("/logout");
      },
      onCancel() {},
    });
  };
  return (
    <Router>
      <Layout style={{ minHeight: "100vh" }}>
        <Sider
          theme="dark"
          collapsible
          collapsed={collapsed}
          onCollapse={() => {
            onCollapse(collapsed);
          }}
        >
          {/* <div className="logo">
            <span>Raymond</span>
            <img src={Logo} className="img-fluid" />
          </div> */}
          <Menu theme="dark" mode="inline">
            {/* <Menu.Item key="1" icon={<PieChartOutlined />}>
              <Link to="/sliders">Sliders</Link>
            </Menu.Item> */}
            <SubMenu key="Homepage" icon={<PictureOutlined />} title="Homepage">
              <Menu.Item key="22">
                <Link to="/sliders">Sliders</Link>
              </Menu.Item>
            </SubMenu>
            <Menu.Item key="8" icon={<ShopOutlined />}>
              <Link to="/business">Our Business</Link>
            </Menu.Item>
            <SubMenu key="Location" icon={<AimOutlined />} title="Our Reach">
              <Menu.Item key="19">
                <Link to="/locations/categories">Category</Link>
              </Menu.Item>
              <Menu.Item key="20">
                <Link to="/locations">Locations</Link>
              </Menu.Item>
            </SubMenu>

            <SubMenu
              key="Our Company"
              icon={<TrophyOutlined />}
              title="Our Company"
            >
              <Menu.Item key="17">
                <Link to="/awards">Awards</Link>
              </Menu.Item>
            </SubMenu>

            <SubMenu key="Brand" icon={<StarOutlined />} title="Our Brands">
              <Menu.Item key="6">
                <Link to="/brands/categories">Category</Link>
              </Menu.Item>
              <Menu.Item key="7">
                <Link to="/brands">Brands</Link>
              </Menu.Item>
            </SubMenu>

            <SubMenu
              key="Beyond Business"
              icon={<GlobalOutlined />}
              title="Beyond Business"
            >
              <Menu.Item key="12">
                <Link to="/sustainability">Sustainability</Link>
              </Menu.Item>
              <Menu.Item key="13">
                <Link to="/sustainability/blocks">Block</Link>
              </Menu.Item>
            </SubMenu>

            <SubMenu
              key="investor"
              icon={<PieChartOutlined />}
              title="Investor"
            >
              <Menu.Item key="14">
                <Link to="/investors/categories">Category</Link>
              </Menu.Item>
              <Menu.Item key="15">
                <Link to="/investors/subcategories">Sub Category</Link>
              </Menu.Item>
              <Menu.Item key="16">
                <Link to="/investors">Policies</Link>
              </Menu.Item>

              <SubMenu key="inv_temp" title="Templates">
                <Menu.Item key="23">
                  <Link to="/investors/template-1">Overview</Link>
                </Menu.Item>
                <Menu.Item key="24">
                  <Link to="/investors/template-2">Board Of Director</Link>
                </Menu.Item>
                <Menu.Item key="25">
                  <Link to="/investors/template-3">Reports</Link>
                </Menu.Item>
                <Menu.Item key="29">
                  <Link to="/investors/template-4">Table</Link>
                </Menu.Item>
              </SubMenu>
            </SubMenu>
            {/* <SubMenu key="Newsroom" icon={<AudioOutlined />} title="Newsroom">
              <Menu.Item key="21">
                <Link to="/interviews">Interviews</Link>
              </Menu.Item>
            </SubMenu> */}

            <SubMenu key="Media" icon={<PlayCircleOutlined />} title="Media">
              <Menu.Item key="18">
                <Link to="/media">Media</Link>
              </Menu.Item>
              <SubMenu key="media_temp" title="Templates">
                <Menu.Item key="26">
                  <Link to="/media/template-1">Overview</Link>
                </Menu.Item>
                <Menu.Item key="27">
                  <Link to="/media/template-2">Board Of Director</Link>
                </Menu.Item>
                <Menu.Item key="28">
                  <Link to="/media/template-3">Reports</Link>
                </Menu.Item>
              </SubMenu>
            </SubMenu>
            <SubMenu key="leads" icon={<LineChartOutlined />} title="Leads">
              {/* <Menu.Item key="11">
                <Link to="/leads/subscribe">Subscribe</Link>
              </Menu.Item> */}
              <Menu.Item key="10">
                <Link to="/leads/partner">Partner</Link>
              </Menu.Item>
              <Menu.Item key="9">
                <Link to="/leads/contact">Contact</Link>
              </Menu.Item>
            </SubMenu>
            <Menu.Item key="30" icon={<FolderOpenOutlined />}>
              <Link to="/media-manager">Media Manager</Link>
            </Menu.Item>

            {/* <SubMenu key="content" icon={<FileTextOutlined />} title="Content">
              <Menu.Item key="29">
                <Link to="/content/homepage">Homepage</Link>
              </Menu.Item>
            </SubMenu> */}
          </Menu>
        </Sider>
        <Layout className="site-layout">
          <Header className="site-layout-background" style={{ padding: 0 }}>
            <Menu theme="dark" mode="horizontal">
              <Menu.Item key="1" icon={<LogoutOutlined />}>
                <Link
                  onClick={() => {
                    showLogoutConfirm();
                  }}
                >
                  Logout
                </Link>
              </Menu.Item>
            </Menu>
          </Header>
          <Content style={{ margin: "0 16px" }}>
            <Switch>
              <Route exact path="/" component={Sliders} />

              <Route exact path="/sliders" component={Sliders} />
              <Route
                exact
                path="/brands/categories"
                component={BrandCategories}
              />
              <Route exact path="/brands" component={Brands} />
              <Route exact path="/business" component={Business} />
              <Route exact path="/leads/contact" component={Contact} />
              <Route exact path="/leads/partner" component={Partner} />
              <Route exact path="/leads/subscribe" component={Subscribe} />
              <Route exact path="/sustainability" component={Sustainability} />
              <Route
                exact
                path="/sustainability/blocks"
                component={SustainabilityBlock}
              />
              <Route exact path="/awards" component={Award} />
              <Route
                exact
                path="/locations/categories"
                component={LocationCategory}
              />
              <Route exact path="/locations" component={Location} />
              <Route exact path="/interviews" component={Interview} />
              <Route exact path="/media" component={Media} />
              <Route exact path="/media/template-1" component={MediaTempOne} />
              <Route exact path="/media/template-2" component={MediaTempTwo} />
              <Route
                exact
                path="/media/template-3"
                component={MediaTempThree}
              />
              <Route exact path="/investors" component={Investor} />
              <Route
                exact
                path="/investors/template-1"
                component={InvestorTempOne}
              />
              <Route
                exact
                path="/investors/template-2"
                component={InvestorTempTwo}
              />
              <Route
                exact
                path="/investors/template-3"
                component={InvestorTempThree}
              />
              <Route
                exact
                path="/investors/template-4"
                component={InvestorTempFour}
              />
              <Route
                exact
                path="/investors/template-4/create"
                component={InvestorTempFourCreate}
              />
              <Route
                exact
                path="/investors/template-4/edit/:id"
                component={InvestorTempFourEdit}
              />
              <Route
                exact
                path="/investors/categories"
                component={InvestorCategories}
              />
              <Route
                exact
                path="/investors/subcategories"
                component={InvestorSubCategories}
              />

              <Route
                exact
                path="/content/homepage"
                component={HomepageContent}
              />
              <Route
                exact
                path="/media-manager"
                component={MediaManager}
              />
              <Route exact path="/logout" component={Logout} />
            </Switch>
          </Content>
          <Footer style={{ textAlign: "center" }}>
            Design & Developed by Togglehead
          </Footer>
        </Layout>
      </Layout>
    </Router>
  );
}

export default MainLayout;
