import React, { useState } from "react";
import {
  Modal,
  Form,
  Input,
  notification,
  Row,
  Col,
  Select,
  Upload,
} from "antd";
import Joi from "joi-browser";

import http from "../../helpers/http";

import config from "../../config/config";

import _ from "lodash";

import HtmlEditor from "../../components/HtmlEditor";
import { PlusOutlined } from "@ant-design/icons";

const Create = ({ isVisible, handleClose, moduleName, medias }) => {
  const [form] = Form.useForm();
  const [loading, setloading] = useState(false);
  const [formError, setFormError] = useState({});
  const [content, setContent] = useState();

  const base_url = config.api_url + "admin/media/template-1"; //without trailing slash
  const image_url = config.api_url + "uploads/media/"; //with trailing slash
  const sizeLimit = 10;

  const { Option } = Select;

  //validation schema

  const validate = async (data) => {
    let schemaObj = {
      mediaId: Joi.string().trim().required().label("media report"),
      // content: Joi.string().trim().required(),
    };

    const schema = Joi.object(schemaObj).options({ allowUnknown: true });

    const { error } = Joi.validate(data, schema, {
      abortEarly: false,
      allowUnknown: true,
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    if (data.image) {
      if (data.image.file.size > sizeLimit * 1024 * 1024) {
        errors["image"] = `File needs to be under ${sizeLimit}MB`;
      }
      let allowedExt = [
        "image/jpeg",
        "image/png",
        "image/svg+xml",
        "image/svg",
      ];
      if (!allowedExt.includes(data.image.file.type)) {
        errors["image"] = "File does not have a valid file extension.";
      }
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (value) => {
    let errors = await validate(value);

    setFormError(errors);

    if (Object.keys(errors).length) {
      return;
    }

    setloading(true);
    let formData = new FormData();
    for (const [key, data] of Object.entries(_.omit(value, ["image"]))) {
      formData.append(key, data || "");
    }
    if (value.image) {
      formData.append("image", value.image.file);
    }

    const { data } = await http.post(base_url, formData);
    // const { data } = await http.post(base_url, value);

    if (data) {
      notification["success"]({
        message: `${moduleName} Added Successfully`,
      });
      setloading(false);

      handleClose();
    }
  };

  return (
    <Modal
      title={`Add ${moduleName}`}
      visible={isVisible}
      onCancel={handleClose}
      okText="Create"
      cancelText="Cancel"
      confirmLoading={loading}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            values.content = content;

            handleSubmit(values);
          })
          .catch((info) => {});
      }}
      width={700}
    >
      <Form layout="vertical" form={form}>
        <Row gutter={15}>
          <Col span={24}>
            <Form.Item
              required
              label="Media Report:"
              name="mediaId"
              {...(formError.mediaId && {
                help: formError.mediaId,
                validateStatus: "error",
              })}
            >
              <Select
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {medias &&
                  medias.map((data) => {
                    return <Option value={data._id}>{data.name}</Option>;
                  })}
              </Select>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              // required
              label="Content:"
              name="content"
              {...(formError.content && {
                help: formError.content,
                validateStatus: "error",
              })}
            >
              <HtmlEditor
                id="create-editor"
                textareaName="content"
                height={350}
                onEditorChange={(newText) => setContent(newText)}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Image:"
              name="image"
              {...(formError.image && {
                help: formError.image,
                validateStatus: "error",
              })}
            >
              <Upload
                beforeUpload={(file) => {
                  return false;
                }}
                maxCount={1}
                listType="picture-card"
                showUploadList={{
                  showPreviewIcon: false,
                  showRemoveIcon: false,
                }}
              >
                <PlusOutlined />
              </Upload>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default Create;
