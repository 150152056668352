import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  notification,
  Upload,
  Row,
  Col,
  InputNumber,
} from "antd";
import { EditOutlined } from "@ant-design/icons";
import Joi from "joi-browser";
import _ from "lodash";
import http from "../../helpers/http";
import config from "../../config/config";
import HtmlEditor from "../../components/HtmlEditor";
import helper from "../../helpers/helper";
import slugify from "react-slugify";

function Edit({ isVisible, handleClose, dbData, moduleName }) {
  const [form] = Form.useForm();
  const [loading, setloading] = useState(false);
  const [formError, setFormError] = useState({});
  const [description, setDescription] = useState();

  const base_url = config.api_url + "admin/business"; //without trailing slash
  const image_url = config.api_url + "uploads/business/"; //with trailing slash
  const sizeLimit = 1;

  const schema = Joi.object({
    title: Joi.string().trim().required(),
    website: Joi.string().allow(""),

    description: Joi.string().trim().required(),
  }).options({ allowUnknown: true });

  const validate = async (value) => {
    const { error } = Joi.validate(value, schema, {
      abortEarly: false,
      allowUnknown: true,
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    // if (value.slug) {
    //   const result = await helper.validateSlug(value.slug, "BrandCategory");

    //   if (result == false) {
    //     errors["slug"] = `This slug is already used.`;
    //   }
    // }

    if (value.image) {
      if (value.image.file.size > sizeLimit * 1024 * 1024) {
        errors["image"] = `File needs to be under ${sizeLimit}MB`;
      }
      let allowedExt = [
        "image/jpeg",
        "image/png",
        "image/svg+xml",
        "image/svg",
      ];
      if (!allowedExt.includes(value.image.file.type)) {
        errors["image"] = "File does not have a valid file extension.";
      }
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (value) => {
    let errors = await validate(value);

    setFormError(errors);

    if (Object.keys(errors).length) {
      return;
    }

    setloading(true);
    let formData = new FormData();
    for (const [key, data] of Object.entries(_.omit(value, ["image"]))) {
      formData.append(key, data || "");
    }

    if (value.image) {
      formData.append("image", value.image.file);
    }

    const { data } = await http.put(`${base_url}/${dbData._id}`, formData);

    if (data) {
      notification["success"]({
        message: `${moduleName} Updated Successfully`,
      });
      setloading(false);
      form.resetFields();
      handleClose();
    }
  };
  useEffect(() => {
    form.setFieldsValue(_.omit(dbData, ["image", "description"]));

    setDescription(() => {
      return dbData.description;
    });
  }, [dbData]);

  return (
    <Modal
      title={`Edit ${moduleName}`}
      visible={isVisible}
      onCancel={handleClose}
      okText="Update"
      cancelText="Cancel"
      confirmLoading={loading}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            values.description = description;
            handleSubmit(values);
          })
          .catch((info) => {});
      }}
      width={1000}
    >
      <Form layout="vertical" form={form}>
        <Row gutter={15}>
          <Col span={8}>
            <Form.Item
              label="Title:"
              name="title"
              required
              {...(formError.title && {
                help: formError.title,
                validateStatus: "error",
              })}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Website:"
              name="website"
              {...(formError.website && {
                help: formError.website,
                validateStatus: "error",
              })}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Sort Order"
              required
              name="sort_order"
              {...(formError.sort_order && {
                help: formError.sort_order,
                validateStatus: "error",
              })}
            >
              <InputNumber min={1} defaultValue={1} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Description:"
              name="description"
              required
              {...(formError.description && {
                help: formError.description,
                validateStatus: "error",
              })}
            >
              <HtmlEditor
                id="edit-editor"
                initialValue={dbData.description}
                textareaName="description"
                height={350}
                onEditorChange={(newText) => setDescription(newText)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              label="Image:"
              name="image"
              required
              {...(formError.image && {
                help: formError.image,
                validateStatus: "error",
              })}
            >
              <Upload
                beforeUpload={(file) => {
                  return false;
                }}
                maxCount={1}
                listType="picture-card"
                showUploadList={{
                  showPreviewIcon: false,
                  showRemoveIcon: false,
                }}
                defaultFileList={[
                  {
                    thumbUrl: image_url + dbData.image,
                  },
                ]}
              >
                <EditOutlined />
              </Upload>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default Edit;
