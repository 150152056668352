import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  notification,
  Row,
  Col,
  Select,
  DatePicker,
  Upload,

} from "antd";
import { EditOutlined } from "@ant-design/icons";

import Joi from "joi-browser";
import _ from "lodash";
import http from "../../helpers/http";
import config from "../../config/config";
import helper from "../../helpers/helper";
import slugify from "react-slugify";
import moment from "moment";

function Edit({ isVisible, handleClose, dbData, moduleName, templates }) {
  const [form] = Form.useForm();
  const [loading, setloading] = useState(false);
  const [formError, setFormError] = useState({});
  // const [description, setDescription] = useState();
  // const [date, setDate] = useState();
  // const [isUrlVisible, setisUrlVisible] = useState(false);
  // const [isInnerSectionVisible, setisInnerSectionVisible] = useState(false);

  const base_url = config.api_url + "admin/investors/categories"; //without trailing slash
  const image_url = config.api_url + "uploads/investor/"; //with trailing slash
  const sizeLimit = 1;

  const { Option } = Select;
  const { TextArea } = Input;

  const validate = async (value) => {
    let schemaObj = {
      name: Joi.string().trim().required(),
      slug: Joi.string().trim().required(),
    };

    const schema = Joi.object(schemaObj).options({ allowUnknown: true });

    const { error } = Joi.validate(value, schema, {
      abortEarly: false,
      allowUnknown: true,
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    if (value.slug && dbData.slug != value.slug) {
      const result = await helper.validateSlug(value.slug, "InvestorCategory");

      if (result == false) {
        errors["slug"] = `This slug is already used.`;
      }
    }

    if (value.image) {
      if (value.image.file.size > sizeLimit * 1024 * 1024) {
        errors["image"] = `File needs to be under ${sizeLimit}MB`;
      }
      let allowedExt = [
        "image/jpeg",
        "image/png",
        "image/svg+xml",
        "image/svg",
      ];
      if (!allowedExt.includes(value.image.file.type)) {
        errors["image"] = "File does not have a valid file extension.";
      }
    }

    return errors ? errors : null;
  };

  // const handleTemplateChange = (template) => {
  //   if (template == "external") {
  //     setisUrlVisible(true);
  //     setisInnerSectionVisible(false);
  //   } else {
  //     setisUrlVisible(false);
  //     setisInnerSectionVisible(true);
  //   }
  // };

  const handleSubmit = async (value) => {
    let errors = await validate(value);

    setFormError(errors);

    if (Object.keys(errors).length) {
      return;
    }

    setloading(true);

    let formData = new FormData();
    for (const [key, data] of Object.entries(_.omit(value, ["image"]))) {
      formData.append(key, data || "");
    }

    if (value.image) {
      formData.append("image", value.image.file);
    }

    const { data } = await http.put(`${base_url}/${dbData._id}`, formData);

    if (data) {
      notification["success"]({
        message: `${moduleName} Updated Successfully`,
      });
      setloading(false);
      form.resetFields();
      handleClose();
    }
  };
  useEffect(() => {
    form.setFieldsValue(_.omit(dbData, ["image"]));
  }, [dbData]);

  return (
    <Modal
      title={`Edit ${moduleName}`}
      visible={isVisible}
      onCancel={handleClose}
      okText="Update"
      cancelText="Cancel"
      confirmLoading={loading}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            handleSubmit(values);
          })
          .catch((info) => {});
      }}
    >
      <Form layout="vertical" form={form}>
        <Row gutter={15}>
          <Col span={24}>
            <Form.Item
              required
              label="Name:"
              name="name"
              {...(formError.name && {
                help: formError.name,
                validateStatus: "error",
              })}
            >
              <Input
                onChange={(value) => {
                  form.setFieldsValue({ slug: slugify(value.target.value) });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              required
              label="Slug:"
              name="slug"
              {...(formError.slug && {
                help: formError.slug,
                validateStatus: "error",
              })}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              label="Image:"
              name="image"
              required
              {...(formError.image && {
                help: formError.image,
                validateStatus: "error",
              })}
            >
              <Upload
                beforeUpload={(file) => {
                  return false;
                }}
                maxCount={1}
                listType="picture-card"
                showUploadList={{
                  showPreviewIcon: false,
                  showRemoveIcon: false,
                }}
                defaultFileList={[
                  {
                    thumbUrl: image_url + dbData.image,
                  },
                ]}
              >
                <EditOutlined />
              </Upload>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default Edit;
