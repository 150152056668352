import React, { useState } from "react";
import {
  Modal,
  Form,
  Input,
  notification,
  Row,
  Col,
  Select,
  DatePicker,
  Switch
} from "antd";
import Joi from "joi-browser";

import http from "../../helpers/http";

import config from "../../config/config";
import helper from "../../helpers/helper";

import _ from "lodash";
import slugify from "react-slugify";

const Create = ({
  isVisible,
  handleClose,
  moduleName,
  templates,
  categories,
}) => {
  const [form] = Form.useForm();
  const [loading, setloading] = useState(false);
  const [formError, setFormError] = useState({});
  // const [description, setDescription] = useState();
  const [date, setDate] = useState();
  const [isUrlVisible, setisUrlVisible] = useState(false);
  const [isFilterVisible, setisFilterVisible] = useState(false);
  const [isInnerSectionVisible, setisInnerSectionVisible] = useState(false);
  const [subCategories, setSubCategories] = useState();

  const base_url = config.api_url + "admin/investors"; //without trailing slash

  const { Option } = Select;
  const { TextArea } = Input;
  //validation schema

  const fetchSubCategory = async (category) => {
    const data = await http.get(
      config.api_url + `admin/investors/subcategories?category=${category}`
    );

    if (data) {
      setSubCategories(data.data);
    }
  };

  const validate = async (data) => {
    let schemaObj = {
      mainCategory: Joi.string().trim().required().label("category"),
      investorCategory: Joi.string().trim().required().label("subcategory"),
      name: Joi.string().trim().required(),
      slug: Joi.string().trim().required(),
      // description: Joi.string().trim().required(),
      // publisher: Joi.string().trim().required(),
      // date: Joi.required(),
      template: Joi.string().trim().required(),
    };

    if (data.template === "external") {
      schemaObj.url = Joi.string().uri().required();
    }
    if (
      data.template === "temp_1" ||
      data.template === "temp_2" ||
      data.template === "temp_3"
    ) {
      // schemaObj.title = Joi.string().trim().required().label("inner title");
      // schemaObj.innerDescription = Joi.string()
      //   .trim()
      //   .required()
      //   .label("inner description");
    }

    const schema = Joi.object(schemaObj).options({ allowUnknown: true });

    const { error } = Joi.validate(data, schema, {
      abortEarly: false,
      allowUnknown: true,
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    // validating unique slug
    if (data.slug) {
      const result = await helper.validateSlug(data.slug, "Investor");

      if (result === false) {
        errors["slug"] = `This slug is already used.`;
      }
    }

    return errors ? errors : null;
  };

  const handleTemplateChange = (template) => {

    if (template === "temp_3") {
      setisFilterVisible(true);
      
    }
    else{
      setisFilterVisible(false);
    }

    if (template === "external") {
      setisUrlVisible(true);
      setisInnerSectionVisible(false);
    } else {
      setisUrlVisible(false);
      setisInnerSectionVisible(true);
    }
  };

  const handleSubmit = async (value) => {
    let errors = await validate(value);

    setFormError(errors);

    if (Object.keys(errors).length) {
      return;
    }

    setloading(true);

    const { data } = await http.post(base_url, value);

    if (data) {
      notification["success"]({
        message: `${moduleName} Added Successfully`,
      });
      setloading(false);

      handleClose();
    }
  };

  return (
    <Modal
      title={`Add ${moduleName}`}
      visible={isVisible}
      onCancel={handleClose}
      okText="Create"
      cancelText="Cancel"
      confirmLoading={loading}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            // values.date = date;
            console.log(values);
            handleSubmit(values);
          })
          .catch((info) => {});
      }}
      width={1000}
    >
      <Form layout="vertical" form={form}>
        <Row gutter={15}>
          <Col span={12}>
            <Form.Item
              label="Category:"
              name="mainCategory"
              {...(formError.mainCategory && {
                help: formError.mainCategory,
                validateStatus: "error",
              })}
            >
              <Select
                placeholder="Select Category"
                onChange={(value) => {
                  fetchSubCategory(value);
                }}
              >
                {categories &&
                  categories.map((data) => {
                    return <Option value={data._id}>{`${data.name}`}</Option>;
                  })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Sub Category:"
              name="investorCategory"
              {...(formError.investorCategory && {
                help: formError.investorCategory,
                validateStatus: "error",
              })}
            >
              <Select placeholder="Select Sub Category">
                {subCategories &&
                  subCategories.map((data) => {
                    return <Option value={data._id}>{`${data.title}`}</Option>;
                  })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              required
              label="Name:"
              name="name"
              {...(formError.name && {
                help: formError.name,
                validateStatus: "error",
              })}
            >
              <Input
                onChange={(value) => {
                  form.setFieldsValue({ slug: slugify(value.target.value) });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              required
              label="Slug:"
              name="slug"
              {...(formError.slug && {
                help: formError.slug,
                validateStatus: "error",
              })}
            >
              <Input />
            </Form.Item>
          </Col>
          {/* <Col span={24}>
              <Form.Item
                required
                label="Publisher:"
                name="publisher"
                {...(formError.publisher && {
                  help: formError.publisher,
                  validateStatus: "error",
                })}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                required
                label="Date:"
                name="date"
                {...(formError.date && {
                  help: formError.date,
                  validateStatus: "error",
                })}
              >
                <DatePicker
                  style={{
                    width: "100%",
                  }}
                  onChange={(date, dateString) => {
                    setDate(dateString);
                  }}
                />
              </Form.Item>
            </Col> */}

          <Col span={8}>
            <Form.Item
              required
              label="Template:"
              name="template"
              {...(formError.template && {
                help: formError.template,
                validateStatus: "error",
              })}
            >
              <Select
                onChange={handleTemplateChange}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {templates &&
                  templates.map((data) => {
                    return <Option value={data.title}>{data.label}</Option>;
                  })}
              </Select>
            </Form.Item>
          </Col>
          {/* <Col span={24}>
              <Form.Item
                required
                label="Short Description:"
                name="description"
                {...(formError.description && {
                  help: formError.description,
                  validateStatus: "error",
                })}
              >
                <TextArea rows={9} />
              </Form.Item>
            </Col> */}
        </Row>

        <Row gutter={15}>
          <Col span={12}>
          <Row gutter={15}>
            <Col
              span={24}
              style={{ display: isInnerSectionVisible ? "block" : "none" }}
            >
              <Form.Item
                // required
                label="Inner Title:"
                name="title"
                {...(formError.title && {
                  help: formError.title,
                  validateStatus: "error",
                })}
              >
                <Input />
              </Form.Item>
            </Col>
            </Row>
            <Col span={24} style={{ display: isUrlVisible ? "block" : "none" }}>
              <Form.Item
                required
                label="URL:"
                name="url"
                {...(formError.url && {
                  help: formError.url,
                  validateStatus: "error",
                })}
              >
                <Input />
              </Form.Item>
            </Col>
            <Row gutter={15}>
            <Col span={12} style={{ display: isFilterVisible ? "block" : "none" }}>
              <Form.Item
                
                label="Want Year Filter?:"
                name="is_year_filter_visible"
                {...(formError.is_year_filter_visible && {
                  help: formError.is_year_filter_visible,
                  validateStatus: "error",
                })}
              >
                 <Switch checkedChildren="yes" unCheckedChildren="no"   />
              </Form.Item>
            </Col>
            <Col span={12} style={{ display: isFilterVisible ? "block" : "none" }}>
              <Form.Item
                
                label="Want Quarter Filter?:"
                name="is_quarter_filter_visible"
                {...(formError.is_quarter_filter_visible && {
                  help: formError.is_quarter_filter_visible,
                  validateStatus: "error",
                })}
              >
                 <Switch checkedChildren="yes" unCheckedChildren="no"   />
              </Form.Item>
            </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Col
              span={24}
              style={{ display: isInnerSectionVisible ? "block" : "none" }}
            >
              <Form.Item
                // required
                label="Inner Description:"
                name="innerDescription"
                {...(formError.innerDescription && {
                  help: formError.innerDescription,
                  validateStatus: "error",
                })}
              >
                <TextArea rows={4} />
              </Form.Item>
            </Col>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default Create;
