import React, { useEffect, useState } from "react";
import { Modal, Form, Input, notification, Row, Col, Select } from "antd";

import Joi from "joi-browser";
import _ from "lodash";
import http from "../../helpers/http";
import config from "../../config/config";

function Edit({ isVisible, handleClose, dbData, moduleName, categories }) {
  const [form] = Form.useForm();
  const [loading, setloading] = useState(false);
  const [formError, setFormError] = useState({});
  const [description, setDescription] = useState();

  const base_url = config.api_url + "admin/locations"; //without trailing slash
  const image_url = config.api_url + "uploads/location/"; //with trailing slash
  const sizeLimit = 1;

  const { Option } = Select;
  const { TextArea } = Input;

  const schema = Joi.object({
    locationCategory: Joi.string().trim().required().label("category"),

    name: Joi.string().trim().required(),

    address: Joi.string().trim().required(),
  }).options({ allowUnknown: true });

  const validate = async (value) => {
    const { error } = Joi.validate(value, schema, {
      abortEarly: false,
      allowUnknown: true,
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (value) => {
    let errors = await validate(value);

    setFormError(errors);

    if (Object.keys(errors).length) {
      return;
    }

    setloading(true);

    const { data } = await http.put(`${base_url}/${dbData._id}`, value);

    if (data) {
      notification["success"]({
        message: `${moduleName} Updated Successfully`,
      });
      setloading(false);
      form.resetFields();
      handleClose();
    }
  };
  useEffect(() => {
    form.setFieldsValue(_.omit(dbData, ["image", "description"]));

    setDescription(() => {
      return dbData.description;
    });
  }, [dbData]);

  return (
    <Modal
      title={`Edit ${moduleName}`}
      visible={isVisible}
      onCancel={handleClose}
      okText="Update"
      cancelText="Cancel"
      confirmLoading={loading}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            values.description = description;
            handleSubmit(values);
          })
          .catch((info) => {});
      }}
    >
      <Form layout="vertical" form={form}>
        <Row gutter={15}>
          <Col span={24}>
            <Form.Item
              required
              label="Category:"
              name="locationCategory"
              {...(formError.locationCategory && {
                help: formError.locationCategory,
                validateStatus: "error",
              })}
            >
              <Select>
                {categories &&
                  categories.map((data) => {
                    return <Option value={data._id}>{data.name}</Option>;
                  })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              required
              label="Name:"
              name="name"
              {...(formError.name && {
                help: formError.name,
                validateStatus: "error",
              })}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Form.Item
              required
              label="Address:"
              name="address"
              {...(formError.address && {
                help: formError.address,
                validateStatus: "error",
              })}
            >
              <TextArea rows={4} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default Edit;
