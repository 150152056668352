import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  notification,
  Row,
  Col,
  Select,
  DatePicker,
} from "antd";

import Joi from "joi-browser";
import _ from "lodash";
import http from "../../helpers/http";
import config from "../../config/config";
import helper from "../../helpers/helper";
import slugify from "react-slugify";
import moment from "moment";

function Edit({ isVisible, handleClose, dbData, moduleName, templates }) {
  const [form] = Form.useForm();
  const [loading, setloading] = useState(false);
  const [formError, setFormError] = useState({});
  // const [description, setDescription] = useState();
  const [date, setDate] = useState();
  const [isUrlVisible, setisUrlVisible] = useState(false);
  const [isInnerSectionVisible, setisInnerSectionVisible] = useState(false);

  const base_url = config.api_url + "admin/media"; //without trailing slash
  const image_url = config.api_url + "uploads/media/"; //with trailing slash
  const sizeLimit = 1;

  const { Option } = Select;
  const { TextArea } = Input;

  const validate = async (value) => {
    let schemaObj = {
      name: Joi.string().trim().required(),
      slug: Joi.string().trim().required(),
      // description: Joi.string().trim().required(),
      // publisher: Joi.string().trim().required(),
      date: Joi.required(),
      template: Joi.string().trim().required(),
    };

    if (value.template == "external") {
      schemaObj.url = Joi.string().required();
    }
    if (
      value.template == "temp_1" ||
      value.template == "temp_2" ||
      value.template == "temp_3"
    ) {
      // schemaObj.title = Joi.string().trim().required().label("inner title");
      // schemaObj.innerDescription = Joi.string()
      //   .required()
      //   .label("inner description");
    }

    const schema = Joi.object(schemaObj).options({ allowUnknown: true });

    const { error } = Joi.validate(value, schema, {
      abortEarly: false,
      allowUnknown: true,
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    if (value.slug && dbData.slug != value.slug) {
      const result = await helper.validateSlug(value.slug, "BrandCategory");

      if (result == false) {
        errors["slug"] = `This slug is already used.`;
      }
    }

    return errors ? errors : null;
  };

  const handleTemplateChange = (template) => {
    if (template == "external") {
      setisUrlVisible(true);
      setisInnerSectionVisible(false);
    } else {
      setisUrlVisible(false);
      setisInnerSectionVisible(true);
    }
  };

  const handleSubmit = async (value) => {
    let errors = await validate(value);

    setFormError(errors);

    if (Object.keys(errors).length) {
      return;
    }

    setloading(true);

    const { data } = await http.put(`${base_url}/${dbData._id}`, value);

    if (data) {
      notification["success"]({
        message: `${moduleName} Updated Successfully`,
      });
      setloading(false);
      form.resetFields();
      handleClose();
    }
  };
  useEffect(() => {
    form.setFieldsValue(_.omit(dbData, ["date"]));

    setDate(moment(dbData.date).format("YYYY-MM-DD"));

    if (dbData.template == "external") {
      setisUrlVisible(true);
      setisInnerSectionVisible(false);
    } else {
      setisUrlVisible(false);
      setisInnerSectionVisible(true);
    }
  }, [dbData]);

  return (
    <Modal
      title={`Edit ${moduleName}`}
      visible={isVisible}
      onCancel={handleClose}
      okText="Update"
      cancelText="Cancel"
      confirmLoading={loading}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            values.date = date;
            handleSubmit(values);
          })
          .catch((info) => {});
      }}
      width={1000}
    >
      <Form layout="vertical" form={form}>
        <Row gutter={15}>
          <Col span={12}>
            <Col span={24}>
              <Form.Item
                required
                label="Name:"
                name="name"
                {...(formError.name && {
                  help: formError.name,
                  validateStatus: "error",
                })}
              >
                <Input
                  onChange={(value) => {
                    form.setFieldsValue({ slug: slugify(value.target.value) });
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                required
                label="Slug:"
                name="slug"
                {...(formError.slug && {
                  help: formError.slug,
                  validateStatus: "error",
                })}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                // required
                label="Publisher:"
                name="publisher"
                {...(formError.publisher && {
                  help: formError.publisher,
                  validateStatus: "error",
                })}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                required
                label="Date:"
                name="date"
                {...(formError.date && {
                  help: formError.date,
                  validateStatus: "error",
                })}
              >
                <DatePicker
                  style={{
                    width: "100%",
                  }}
                  defaultValue={moment(dbData.date)}
                  onChange={(date, dateString) => {
                    setDate(dateString);
                  }}
                />
              </Form.Item>
            </Col>
          </Col>
          <Col span={12}>
            <Col span={24}>
              <Form.Item
                required
                label="Template:"
                name="template"
                {...(formError.template && {
                  help: formError.template,
                  validateStatus: "error",
                })}
              >
                <Select
                  onChange={handleTemplateChange}
                  showSearch
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {templates &&
                    templates.map((data) => {
                      return <Option value={data.title}>{data.label}</Option>;
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                // required
                label="Short Description:"
                name="description"
                {...(formError.description && {
                  help: formError.description,
                  validateStatus: "error",
                })}
              >
                <TextArea rows={9} />
              </Form.Item>
            </Col>
          </Col>
        </Row>

        <Row gutter={15}>
          <Col span={12}>
            <Col
              span={24}
              style={{ display: isInnerSectionVisible ? "block" : "none" }}
            >
              <Form.Item
                // required
                label="Inner Title:"
                name="title"
                {...(formError.title && {
                  help: formError.title,
                  validateStatus: "error",
                })}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24} style={{ display: isUrlVisible ? "block" : "none" }}>
              <Form.Item
                required
                label="URL:"
                name="url"
                {...(formError.url && {
                  help: formError.url,
                  validateStatus: "error",
                })}
              >
                <Input />
              </Form.Item>
            </Col>
          </Col>
          <Col span={12}>
            <Col
              span={24}
              style={{ display: isInnerSectionVisible ? "block" : "none" }}
            >
              <Form.Item
                // required
                label="Inner Description:"
                name="innerDescription"
                {...(formError.innerDescription && {
                  help: formError.innerDescription,
                  validateStatus: "error",
                })}
              >
                <TextArea rows={4} />
              </Form.Item>
            </Col>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default Edit;
