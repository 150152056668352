import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  notification,
  Upload,
  Row,
  Col,
  InputNumber,
} from "antd";
import { EditOutlined } from "@ant-design/icons";
import Joi from "joi-browser";
import _ from "lodash";
import http from "../../helpers/http";
import config from "../../config/config";
import HtmlEditor from "../../components/HtmlEditor";
import helper from "../../helpers/helper";
import slugify from "react-slugify";

function Edit({ isVisible, handleClose, dbData, moduleName }) {
  const [form] = Form.useForm();
  const [loading, setloading] = useState(false);
  const [formError, setFormError] = useState({});
  const [content, setContent] = useState();

  const base_url = config.api_url + "admin/interviews"; //without trailing slash
  const image_url = config.api_url + "uploads/interview/"; //with trailing slash
  const sizeLimit = 1;

  const { TextArea } = Input;

  const schema = Joi.object({
    name: Joi.string().trim().required(),
    slug: Joi.string().trim().required(),
    designation: Joi.string().trim().required(),

    title: Joi.string().trim().required(),
    description: Joi.string().trim().required(),
    quote: Joi.string().trim().required(),
    youtube_link: Joi.string().required().uri().label("youtube link"),
    content: Joi.string().trim().required(),
  }).options({ allowUnknown: true });

  const validate = async (value) => {
    const { error } = Joi.validate(value, schema, {
      abortEarly: false,
      allowUnknown: true,
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    if (value.slug && dbData.slug != value.slug) {
      const result = await helper.validateSlug(value.slug, "Interview");

      if (result == false) {
        errors["slug"] = `This slug is already used.`;
      }
    }

    if (value.image) {
      if (value.image.file.size > sizeLimit * 1024 * 1024) {
        errors["image"] = `File needs to be under ${sizeLimit}MB`;
      }
      let allowedExt = [
        "image/jpeg",
        "image/png",
        "image/svg+xml",
        "image/svg",
      ];
      if (!allowedExt.includes(value.image.file.type)) {
        errors["image"] = "File does not have a valid file extension.";
      }
    }

    if (value.thumbnail) {
      if (value.thumbnail.file.size > sizeLimit * 1024 * 1024) {
        errors["thumbnail"] = `File needs to be under ${sizeLimit}MB`;
      }
      let allowedExt = [
        "image/jpeg",
        "image/png",
        "image/svg+xml",
        "image/svg",
      ];
      if (!allowedExt.includes(value.thumbnail.file.type)) {
        errors["thumbnail"] = "File does not have a valid file extension.";
      }
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (value) => {
    let errors = await validate(value);

    setFormError(errors);

    if (Object.keys(errors).length) {
      return;
    }

    setloading(true);
    let formData = new FormData();
    for (const [key, data] of Object.entries(
      _.omit(value, ["image", "thumbnail"])
    )) {
      formData.append(key, data || "");
    }

    if (value.image) {
      formData.append("image", value.image.file);
    }
    if (value.thumbnail) {
      formData.append("thumbnail", value.thumbnail.file);
    }

    const { data } = await http.put(`${base_url}/${dbData._id}`, formData);

    if (data) {
      notification["success"]({
        message: `${moduleName} Updated Successfully`,
      });
      setloading(false);
      form.resetFields();
      handleClose();
    }
  };
  useEffect(() => {
    form.setFieldsValue(_.omit(dbData, ["image", "thumbnail", "content"]));

    setContent(() => {
      return dbData.content;
    });
  }, [dbData]);

  return (
    <Modal
      title={`Edit ${moduleName}`}
      visible={isVisible}
      onCancel={handleClose}
      okText="Update"
      cancelText="Cancel"
      confirmLoading={loading}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            values.content = content;
            handleSubmit(values);
          })
          .catch((info) => {});
      }}
      width={1000}
    >
      <Form layout="vertical" form={form}>
        <Row gutter={15}>
          <Col span={8}>
            <Form.Item
              required
              label="Name:"
              name="name"
              {...(formError.name && {
                help: formError.name,
                validateStatus: "error",
              })}
            >
              <Input
                onChange={(value) => {
                  form.setFieldsValue({ slug: slugify(value.target.value) });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              required
              label="Slug:"
              name="slug"
              {...(formError.slug && {
                help: formError.slug,
                validateStatus: "error",
              })}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Sort Order"
              required
              name="sort_order"
              {...(formError.sort_order && {
                help: formError.sort_order,
                validateStatus: "error",
              })}
            >
              <InputNumber min={1} defaultValue={1} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              required
              label="Designation:"
              name="designation"
              {...(formError.designation && {
                help: formError.designation,
                validateStatus: "error",
              })}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              required
              label="Title:"
              name="title"
              {...(formError.title && {
                help: formError.title,
                validateStatus: "error",
              })}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              required
              label="Youtube Embed Link:"
              name="youtube_link"
              {...(formError.youtube_link && {
                help: formError.youtube_link,
                validateStatus: "error",
              })}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              required
              label="Description:"
              name="description"
              {...(formError.description && {
                help: formError.description,
                validateStatus: "error",
              })}
            >
              <TextArea rows={4} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              required
              label="Quote:"
              name="quote"
              {...(formError.quote && {
                help: formError.quote,
                validateStatus: "error",
              })}
            >
              <TextArea rows={4} />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Form.Item
              required
              label="Content:"
              name="content"
              {...(formError.content && {
                help: formError.content,
                validateStatus: "error",
              })}
            >
              <HtmlEditor
                id="edit-editor"
                initialValue={dbData.content}
                textareaName="content"
                height={350}
                onEditorChange={(newText) => setContent(newText)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              label="Image:"
              name="image"
              required
              {...(formError.image && {
                help: formError.image,
                validateStatus: "error",
              })}
            >
              <Upload
                beforeUpload={(file) => {
                  return false;
                }}
                maxCount={1}
                listType="picture-card"
                showUploadList={{
                  showPreviewIcon: false,
                  showRemoveIcon: false,
                }}
                defaultFileList={[
                  {
                    thumbUrl: image_url + dbData.image,
                  },
                ]}
              >
                <EditOutlined />
              </Upload>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Thumbnail:"
              name="thumbnail"
              required
              {...(formError.thumbnail && {
                help: formError.thumbnail,
                validateStatus: "error",
              })}
            >
              <Upload
                beforeUpload={(file) => {
                  return false;
                }}
                maxCount={1}
                listType="picture-card"
                showUploadList={{
                  showPreviewIcon: false,
                  showRemoveIcon: false,
                }}
                defaultFileList={[
                  {
                    thumbUrl: image_url + dbData.thumbnail,
                  },
                ]}
              >
                <EditOutlined />
              </Upload>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default Edit;
